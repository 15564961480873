/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, title }: any) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription =
    description || siteMetadata.description || "description"
  const defaultTitle = title || siteMetadata.title || "title"

  return (
    <Helmet
    // htmlAttributes={{
    //   lang: `en`,
    // }}
    // title={defaultTitle}
    // titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ""}
    // meta={[
    //   {
    //     name: `description`,
    //     content: metaDescription,
    //   },
    //   // ,
    //   // {
    //   //   property: `og:title`,
    //   //   content: defaultTitle,
    //   // },
    //   // {
    //   //   property: `og:description`,
    //   //   content: metaDescription,
    //   // },
    //   // {
    //   //   property: `og:type`,
    //   //   content: `website`,
    //   // },
    // ].concat(meta)}
    // />
    >
      <title>{defaultTitle}</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
  )
}

export default SEO
