/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import styled from "styled-components"
import "./layout.css"

const Div = styled.div`
  margin: 0;
  max-width: 960;
  padding: 0;
`
const Footer = styled.footer`
  margin-top: 2rem;
  padding-bottom: 8px;
  color: grey;
`

const Layout: React.FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <Div>
        <main>{children}</main>
        {/* TODO: privacy policy, etc */}
        <Footer>KaraNook &copy; 2021</Footer>
      </Div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
