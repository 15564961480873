// import {  PageProps } from "gatsby"
import React from "react"
import styled from "styled-components"

const StyledHeader = styled.header`
  background-color: #e65eda;
  margin-bottom: 0;
  z-index: 2;
`
const Container = styled.div`
  margin: 0 auto;
  max-width: 960;
  padding: 1.5rem;
`
const H1 = styled.h1`
  margin: 0;
  font-size: 15vmin;
`
const Alink = styled.a`
  color: white;
  text-decoration: none;
`
// const linkStyle = { color: "white", textDecoration: "none" }
const Header: React.FC<any> = ({ siteTitle }) => (
  <StyledHeader>
    <Container>
      <br />
      <H1>
        <Alink href="/#contact-form">{siteTitle}</Alink>
      </H1>
      <br />
      {/* <h6>where karaoke is life and life is suffering</h6> */}
    </Container>
  </StyledHeader>
)

export default Header
